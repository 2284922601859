import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const CategoryContent = props => {
  const { blogCategory } = props
  return (
    <div id="asm-category-content" className="col-12 col-md-6 offset-md-1 py-5">
      <div className="row">
        {blogCategory.blog_subcategorias.length > 0 ? (
          blogCategory.blog_subcategorias.map((blogSubcategory, index) => {
            return (
              <div className="col-4 subcategory-card text-center" key={index}>
                <Link
                  to={`/blog/${blogSubcategory.slug}`}
                  className="d-block"
                  role="button"
                >
                  <div className="subcategory-img-container rounded-circle p-2 mx-auto my-2">
                    <img
                      src={
                        blogSubcategory.icono_principal
                          ? blogSubcategory.icono_principal.publicURL
                          : "http://placehold.it/100x100"
                      }
                      alt={blogSubcategory.nombre}
                      className="mx-auto d-block"
                    />
                  </div>
                  <h5 className="subcategory-heading text-black-beta text-600">
                    {blogSubcategory.nombre}
                  </h5>
                </Link>
              </div>
            )
          })
        ) : (
          <p>No hay contenido disponible!</p>
        )}
      </div>
    </div>
  )
}

CategoryContent.propTypes = {
  blogCategory: PropTypes.shape({
    strapiId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    blog_subcategorias: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        nombre: PropTypes.string.isRequired,
        icono_principal: PropTypes.shape({
          publicURL: PropTypes.string.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
}

CategoryContent.defaultProps = {
  blogCategory: {
    strapiId: "5fc6712bb2b37d04310d0a34",
    slug: `nombre-categoria`,
    nombre: "Categoría",
    blog_subcategorias: [
      {
        nombre: "Subcategoría #1",
        id: "5fc66cf1e30179b4480561aa",
        icono_principal: {
          publicURL: "http://placehold.it/150x150",
        },
      },
      {
        nombre: "Subcategoría #2",
        id: "5fc66d00e30179b4480561ab",
        icono_principal: {
          publicURL: "http://placehold.it/150x150",
        },
      },
    ],
  },
}

export default CategoryContent
