import React from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../components/layout"
// Components
import CategoryBanner from "../components/blog/category-banner"
import CategoryContent from "../components/blog/category-content"
import CategoriesSidebar from "../components/blog/categories-sidebar"
// Hooks
import { useBlogCategories } from "../hooks/use-blog-categories"

const BlogCategoryTemplate = ({ data }) => {
  const blogCategory = data.blogCategory
  const blogCategories = useBlogCategories()

  return (
    <Layout
      simpleLayout={true}
      meta={{
        og_tipo: "blog",
        og_titulo: blogCategory.nombre,
        og_imagen: blogCategory.imagen_principal || null,
      }}
    >
      <CategoryBanner blogCategory={blogCategory} />
      <div id="asm-category-main" className="container-fluid position-relative">
        <div className="container">
          <div className="row">
            <CategoriesSidebar
              blogCategories={blogCategories}
              blogCategoryId={blogCategory.strapiId}
            />
            <CategoryContent blogCategory={blogCategory} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogCategoryTemplate

export const query = graphql`
  query BlogCategoryQuery($id: String!) {
    blogCategory: strapiBlogCategoria(strapiId: { eq: $id }) {
      strapiId
      slug
      nombre
      destacado
      
      blog_subcategorias {
        id
        slug
        nombre
        icono_principal {
          publicURL
        }
      }
      
      icono_principal {
        publicURL
      }
      imagen_principal {
        publicURL
      }
    }
  }
`
